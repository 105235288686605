$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p === "organizer_event_ticket_category" && a === "list") {
    let order_by = "name";
    let order_by_direction = "asc";
    let status = 1;
    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();
      let page = parseInt($(this).attr("href").substring(6));
      let location_id = $("body").attr("event_id");
      let search_string = $("#product-search-button").attr(
        "data-search-string"
      );
      let supplierId = $("#sort").attr("data-supplier-id");
      let product_group_unique_id = $("#product_group_unique_id").val();
      let product_type = $("#product_type").val();
      let liveEdit = $("#event_ticket_category_table").attr("data-live-edit");
      getProductList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        product_type,
        supplierId,
        product_group_unique_id,
        liveEdit,
        status
      );
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      let page = $("#event_ticket_category_table").attr("data-page");
      let location_id = $("body").attr("event_id");
      let search_string = $("#product-search-button").attr(
        "data-search-string"
      );
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");
      let supplierId = $(this).attr("data-supplier-id");
      let product_group_unique_id = $("#product_group_unique_id").val();
      let product_type = $("#product_type").val();
      let liveEdit = $("#event_ticket_category_table").attr("data-live-edit");

      getProductList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        product_type,
        supplierId,
        product_group_unique_id,
        liveEdit,
        status
      );
    });

    function getProductList(
      location_id = null,
      page = 1,
      order_by = null,
      order_by_direction = null,
      search_string = "",
      product_type,
      supplier_id = null,
      product_group_unique_id = null,
      live_edit,
      status
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: {
          location_id,
          page,
          search_string,
          order_by,
          order_by_direction,
          product_type,
          supplier_id,
          product_group_unique_id,
          live_edit,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);

            const url = new URL(window.location);
            url.searchParams.set("page", page ?? 1);
            window.history.pushState({}, '', url);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
