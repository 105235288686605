$(document).ready(function () {
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery = $("body").attr("start_date") || null;
  let endDateFromQuery = $("body").attr("end_date") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;
  if (p === "statistic_order") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );
    handleCustomerIdChange(getAmountOfOrderAndSale);
    handleCustomPeriodChange(getAmountOfOrderAndSale);
    handleTopProductGroupChange(getAmountOfOrderAndSale);
    handleSupplierIdChange(getAmountOfOrderAndSale);
    handleProductGroupChange(getAmountOfOrderAndSale);
    handleUserChange(getAmountOfOrderAndSale);
    handlePlatformChange(getAmountOfOrderAndSale);
    handlePeriodChange(getAmountOfOrderAndSale);
    handleEndDateChange(getAmountOfOrderAndSale);
    handleStartDateChange(getAmountOfOrderAndSale);
    handlePreviousButton(getAmountOfOrderAndSale);
    handleNextButton(getAmountOfOrderAndSale);
    handleCompareByChange(getAmountOfOrderAndSale);

    function eventLineChart(
      categoryArray,
      seriesData1,
      seriesData2,
      seriesData3,
      interval = null
    ) {
      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: {
          type: "column",
        },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 4, // Adjust line width as needed
          },
        },

        xAxis: {
          categories: categoryArray,
          labels: {
            formatter: function () {
              if (startDate.split("T")[0] === endDate.split("T")[0]) {
                return this.value;
              } else if (customPeriod === "week") {
                return "Uke " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat(
                  "%B",
                  new Date(this.value).getTime()
                );
              } else {
                return Highcharts.dateFormat(
                  "%e/%b",
                  new Date(this.value).getTime()
                );
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          title: {
            text: "Ordrebeløp",
          },
        },
        series:
          seriesData2.length === 0 && seriesData3.length === 0
            ? [
                {
                  name: "Ordrebeløp",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ]
            : [
                {
                  name: "Ordrebeløp",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Ordrebeløp forrige uke",
                  data: seriesData2.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Ordrebeløp forrige år",
                  data: seriesData3.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ],
        tooltip: {
          formatter: function () {
            var tooltipText;
            if (startDate.split("T")[0] === endDate.split("T")[0]) {
              // Default formatting for datetime xAxis
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", this.x) +
                "</b><br/>" +
                "Ordrebeløp " +
                formatNumber(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                this.x +
                "</b><br/>" +
                "Ordrebeløp " +
                formatNumber(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", this.x) +
                "</b><br/>" +
                "Ordrebeløp " +
                formatNumber(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", this.x) +
                "</b><br/>" +
                "Ordrebeløp " +
                formatNumber(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate.split("T")[0]);
        $("#end-date").val(endDate.split("T")[0]);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }
    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }
    function getAmountOfOrderAndSale(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0,
      customerId = 0,
      compareBy = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_order/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
          customerId,
          compareBy,
        },
      })
        .done(function (data) {
          if (data.totalQuantity) {
            $("#key-figure-sale").text(data.totalQuantity);
            $("#key-figure-order").text(data.totalQuantity);
          } else {
            $("#key-figure-sale").text("0");
            $("#key-figure-order").text("0");
          }
          if (
            data.xAxisArray.length > 0 &&
            data.seriesData1 &&
            data.seriesData2 &&
            data.seriesData3
          ) {
            eventLineChart(
              data.xAxisArray,
              data.seriesData1,
              data.seriesData2,
              data.seriesData3
            );
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });

          if (
            data &&
            data.orderListTable !== undefined &&
            data.orderListTable !== null &&
            data.orderListTable !== ""
          ) {
            $("#order-list-view").html(data.orderListTable);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
    function getHourGraph(data) {
      let hourArray = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];

      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let xAxisArray = [];
      $.map(hourArray, function (hour) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === hour.toString();
        });
        let totalQuantity = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalQuantity += parseInt(ele.order_amount);
          });
        }

        if (compareBy && compareBy != 0) {
          let foundedLastWeek = $.grep(data.lastWeekList, function (item) {
            return item.order_date === hour.toString();
          });
          let foundedLastYear = $.grep(data.lastYearList, function (item) {
            return item.order_date === hour.toString();
          });

          let totalTurnOver2 = 0;
          let totalTurnOver3 = 0;

          if (foundedLastWeek.length > 0) {
            $.map(foundedLastWeek, function (item) {
              totalTurnOver2 += parseInt(item.order_amount);
            });
          }
          if (foundedLastYear.length > 0) {
            $.map(foundedLastYear, function (item) {
              totalTurnOver3 += parseInt(item.order_amount);
            });
          }
          seriesData2.push(totalTurnOver2);
          seriesData3.push(totalTurnOver3);
        }
        seriesData1.push(totalQuantity);
        xAxisArray.push(
          (hour.toString().length === 1
            ? "0" + hour.toString()
            : hour.toString()) + ":00"
        );
      });
      let interval = 3600 * 1000;
      eventLineChart(
        xAxisArray,
        seriesData1,
        seriesData2,
        seriesData3,
        interval
      );
    }

    function getDayGraph(data) {
      let dailyTotals = {};
      let dailyTotals2 = {};
      let dailyTotals3 = {};

      data.currentList.forEach(function (item) {
        if (Array.isArray(item) && item.length === 0) {
          // Skip empty arrays
          return;
        }
        let date = item.order_date.split(" ")[0];
        if (dailyTotals[date]) {
          dailyTotals[date] += parseInt(item.order_amount);
        } else {
          dailyTotals[date] = parseInt(item.order_amount);
        }
      });

      // Prepare data for Highcharts
      let dateArray = [];
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let startedDate = new Date(startDate.split("T")[0]);

      if (compareBy && compareBy !== "0") {
        data.lastWeekList.forEach(function (item) {
          let lastWeekDate = item.order_date.split(" ")[0];

          if (dailyTotals2[lastWeekDate]) {
            dailyTotals2[lastWeekDate] += parseInt(item.order_amount);
          } else {
            dailyTotals2[lastWeekDate] = parseInt(item.order_amount);
          }
        });
        data.lastYearList.forEach(function (item) {
          let lastYearDate = item.order_date.split(" ")[0];
          if (dailyTotals3[lastYearDate]) {
            dailyTotals3[lastYearDate] += parseInt(item.order_amount);
          } else {
            dailyTotals3[lastYearDate] = parseInt(item.order_amount);
          }
        });
      }

      let endedDate = new Date(endDate.split("T")[0]);
      endedDate.setHours(23, 59, 59, 0);

      while (startedDate <= endedDate) {
        let dateStr = startedDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
        let total1 = dailyTotals[dateStr] || 0; // Use total from dailyTotals or 0 if not available

        dateArray.push(dateStr);
        seriesData1.push(total1);

        startedDate.setDate(startedDate.getDate() + 1); // Move to the next day
      }

      if (compareBy) {
        const newStartDate = moment(startDate.split("T")[0]);
        const newEndDate = moment(endDate.split("T")[0]);

        // Get the same date for the previous week, month, and year
        let lastWeekStartDate = newStartDate
          .clone()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        let lastMonthStartDate = newStartDate
          .clone()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        let lastYearStartDate = newStartDate
          .clone()
          .subtract(1, "year")
          .format("YYYY-MM-DD");

        let lastYearEndDate = newEndDate
          .clone()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        let lastWeekEndDate = newEndDate
          .clone()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        let lastMonthEndDate = newEndDate
          .clone()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        if (
          (period === "week" || period === "month") &&
          customPeriod === "day"
        ) {
          while (lastWeekStartDate <= lastWeekEndDate) {
            let total2 = dailyTotals2[lastWeekStartDate] || 0; // Use total from dailyTotals or 0 if not available
            seriesData2.push(total2);

            lastWeekStartDate = moment(lastWeekStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }

          while (lastYearStartDate <= lastYearEndDate) {
            let total3 = dailyTotals3[lastYearStartDate] || 0; // Use total from dailyTotals or 0 if not available

            seriesData3.push(total3);

            lastYearStartDate = moment(lastYearStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
        }
      }

      let interval = 24 * 3600 * 1000;
      eventLineChart(
        dateArray,
        seriesData1,
        seriesData2,
        seriesData3,
        interval
      );
    }

    function getWeekGraph(data) {
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let weekNumbers = [];

      while (startDay.isBefore(endDay)) {
        let weekNumber = startDay.isoWeek();
        weekNumbers.push(weekNumber);
        startDay.add(1, "day");
      }
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      $.map(weekNumbers, function (week_number) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === week_number.toString();
        });

        let totalWeeklyTurnover1 = 0;
        let totalWeeklyTurnover2 = 0;
        let totalWeeklyTurnover3 = 0;
        if (found.length > 0) {
          $.map(found, function (item) {
            totalWeeklyTurnover1 += parseInt(item.order_amount);
          });
        }
        seriesData1.push(totalWeeklyTurnover1);

        if (compareBy) {
          let found2 = $.grep(data.lastWeekList, function (item) {
            return item.order_date === week_number.toString();
          });
          if (found2.length > 0) {
            $.map(found2, function (item) {
              totalWeeklyTurnover2 += parseInt(item.order_amount);
            });
            seriesData2.push(totalWeeklyTurnover1);
          }
          let found3 = $.grep(data.lastYearList, function (item) {
            return item.order_date === week_number.toString();
          });
          if (found3.length > 0) {
            $.map(found3, function (item) {
              totalWeeklyTurnover3 += parseInt(item.order_amount);
            });
            seriesData3.push(totalWeeklyTurnover3);
          }
        }
      });

      eventLineChart(weekNumbers, seriesData1, seriesData2, seriesData3);
    }

    function getMonthGraph(data) {
      data.sort(
        (a, b) => new Date(a.order_confirmed) - new Date(b.order_confirmed)
      );
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let monthNumbers = [];

      while (startDay.isBefore(endDay)) {
        let monthNumber = startDay.month() + 1;
        monthNumbers.push(monthNumber);
        startDay.add(1, "month");
      }
      let seriesData = [];
      $.map(monthNumbers, function (month_number) {
        let found = $.grep(data, function (item) {
          return item.order_date === month_number.toString();
        });
        let monthNumberToDate = moment()
          .startOf("year")
          .add(month_number - 1, "month")
          .format("YYYY-MM-DD");
        let totalOrderAmount = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalOrderAmount += ele.order_amount;
          });
        }

        let formatedDate = new Date(monthNumberToDate);
        let timestamp = formatedDate.getTime();
        let dataSet = [timestamp, totalOrderAmount];
        seriesData.push(dataSet);
      });
      eventLineChart(seriesData);
    }

    function displayGraphDynamically(durationOfstartAndEndDate) {
      if (isMobile) {
        if (durationOfstartAndEndDate <= 7) {
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 8 &&
          durationOfstartAndEndDate < 49
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else if (isTablet) {
        if (durationOfstartAndEndDate <= 14) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 15 &&
          durationOfstartAndEndDate < 98
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else {
        if (durationOfstartAndEndDate <= 31) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 32 &&
          durationOfstartAndEndDate < 217
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      }
    }

    getAmountOfOrderAndSale(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId
    );
    toggleCustomDatePicker();
  }
});
