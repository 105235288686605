$(document).ready(function () {
  $("select[name=extra_product_id]").select2({
    theme: "bootstrap4",
  });

  $("select[name=product_extra_template_id]").select2({
    theme: "bootstrap4",
  });

  $(document).on(
    "input",
    "#ticket_price_ex_vat, #ticket_price_incl_vat, #degree_of_coverage, #coverage_contribution, #price-inc-mva-value, #price-ex-mva-value, #volume, .drag-update-input",
    function (event) {
      const regex = /^[0-9]*[.,]?[0-9]*$/;

      const inputValue = $(this).val();

      if (!regex.test(inputValue)) {
        $(this).val(inputValue.slice(0, -1));
      }
    }
  );

  $(document).on("change", "#printer_id", function () {
    if ($(this).prop("checked")) {
      return;
    } else {
      $("#printer-id").val("");
    }
  });

  $(document).on("change", "#is_volume", function () {
    if ($(this).prop("checked")) {
      $("#volume").prop("required", true);
      $("#volume").prop("type", "number");
    } else {
      $("#volume").removeAttr("required");
      $("#volume").prop("type", "hidden");
    }
  });

  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let language = $("body").attr("lang");
  const menuProducts = [];
  if (p == "organizer_event_ticket_category" && a == "new") {
    let productExtraTemplateIdList = [];
    let productExtraTemplateList = [];
    let extraProductIdList = [];
    let extraProductList = [];
    let extraProductTextList = [];
    let extraProductTextListToShow = [];
    let degreeOfCoverage = formattedValueForNumberInput(
      $("#degree_of_coverage").val()
    );
    let coverageOfContribution = formattedValueForNumberInput(
      $("#coverage_contribution").val()
    );
    let productPrice = formattedValueForNumberInput(
      $("#ticket_price_ex_vat").val()
    );
    let mva = $("#vat_code").children("option:selected").text();
    let priceExMva = formattedValueForNumberInput(
      $("#price-ex-mva-value").val()
    );
    let priceIncMva = formattedValueForNumberInput(
      $("#price-inc-mva-value").val()
    );

    $(document).on("blur", "#product-sku-input", function (e) {
      e.preventDefault();
      const encodedData = $("#existing_product_number").attr(
        "data-product-number-list"
      );
      const existingNumberList = JSON.parse(atob(encodedData));
      const newValue = $(this).val();
      const oldValue = $(this).attr("data-old-value");
      const filteredexistingNumbers = existingNumberList.filter(
        (number) => number !== oldValue
      );

      if (filteredexistingNumbers.includes(newValue)) {
        alert("varenummer finnes allerede!");
        $(this).val(oldValue);
        $(this).focus();
        return;
      }
    });

    $(document).on("change", "#ticket_price_ex_vat", function (e) {
      e.preventDefault();
      productPrice = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "ticket_price_ex_vat",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#degree_of_coverage", function (e) {
      e.preventDefault();

      degreeOfCoverage = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "degree_of_coverage",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#coverage_contribution", function (e) {
      e.preventDefault();
      coverageOfContribution = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "coverage_contribution",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#price-ex-mva-value", function (e) {
      e.preventDefault();
      priceExMva = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "price_ex_mva_value",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#price-inc-mva-value", function (e) {
      e.preventDefault();
      priceIncMva = formattedValueForNumberInput($(this).val());
      const payload = {
        field: "price_inc_mva_value",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on("change", "#vat_code", function (e) {
      e.preventDefault();
      mva = $(this).children("option:selected").text();
      const payload = {
        field: "mva",
        productPrice,
        priceExMva,
        priceIncMva,
        degreeOfCoverage,
        coverageOfContribution,
        mva: parseInt(mva),
      };

      $.ajax({
        type: "POST",
        url: "/api/v1/private/products/calculate-price",
        data: payload,
        async: true,
        cache: false,
      })
        .done(function (data) {
          updateInputValues(data);
        })
        .fail(function (data) {
          console.log(data);
        });
    });

    $(document).on(
      "click",
      "#product-extra-from-template-register-button",
      function () {
        let productExtraTemplateId = $("#product_extra_template_id").val();
        let productExtraTemplateName = $(
          "#product_extra_template_id option:selected"
        ).text();
        $("#product_extra_template_id").select2({ theme: "bootstrap4" });
        let selectedValue = $("#product_extra_template_id").val();

        // Hide the selected option
        $(
          "#product_extra_template_id option[value='" + selectedValue + "']"
        ).prop("disabled", true);

        // Select the next visible option
        let options = $("#product_extra_template_id option:not(:disabled)");
        if (options.length > 0) {
          let nextOption = $(options[0]);
          nextOption.prop("selected", true);
        }

        // Refresh Select2
        $("#product_extra_template_id").trigger("change");
        if (
          $.inArray(productExtraTemplateId, productExtraTemplateIdList) ===
            -1 &&
          productExtraTemplateId !== null &&
          productExtraTemplateId != 0
        ) {
          productExtraTemplateIdList.push(productExtraTemplateId);

          $.ajax({
            type: "POST",
            url: "/ajax/organizer_event_ticket_category/get_product_extra",
            data: { id: productExtraTemplateId },
          }).done(function (data) {
            data.map(function (item) {
              productExtraTemplateList.push({
                id: item.id,
                name: item.name ? item.name : item.extra_text,
              });
            });

            popupAlert();
            showSelectedExtraProduct();
          });
        }
      }
    );

    $(document).on("click", "#product-extra-register-button", function () {
      let extraProductId = $("#extra_product_id").val();
      let extraProdcutName = $("#extra_product_id option:selected").text();

      // Refresh Select2
      $("#extra_product_id").trigger("change");
      $("#extra_product_id").select2({ theme: "bootstrap4" });
      if (
        $.inArray(extraProductId, extraProductIdList) === -1 &&
        extraProductId !== null &&
        extraProductId != 0
      ) {
        let selectedValue = $("#extra_product_id").val();

        // Hide the selected option
        $("#extra_product_id option[value='" + selectedValue + "']").prop(
          "disabled",
          true
        );
        $("#extra_product_id option[value='" + selectedValue + "']").addClass(
          "hidden"
        );

        // Select the next visible option
        let options = $("#extra_product_id option:not(:disabled)");
        if (options.length > 0) {
          let nextOption = $(options[0]);
          nextOption.prop("selected", true);
        }
        extraProductIdList.push(extraProductId);
        extraProductList.push({
          id: extraProductId,
          name: extraProdcutName,
        });
        showSelectedExtraProduct();
      } else {
        return;
      }
    });

    let i = 1;
    $(document).on("click", "#extra-product-text-add-button", function () {
      let extraProductText = $("#extra-product-text").val();
      if (
        extraProductText !== "" &&
        !extraProductTextList.includes(extraProductText)
      ) {
        extraProductTextList.push(extraProductText);
        extraProductTextListToShow.push({ id: i, name: extraProductText });
        i++;
        showSelectedExtraProduct();
      }
      $("#extra-product-text").val("");
      $("#collapse-extra-product-text").collapse("hide");
    });

    $(document).on("click", ".product-extra-delete-button", function () {
      let productId = $(this).attr("data-product-id");
      let productType = $(this).attr("data-product-type");
      if (productType === "extra") {
        extraProductIdList = $.grep(extraProductIdList, function (id) {
          return id !== productId;
        });
        extraProductList = $.grep(extraProductList, function (item) {
          return item.id !== productId;
        });

        $("#extra_product_id option[value='" + productId + "']").prop(
          "disabled",
          false
        );
      } else if (productType === "extra-template") {
        productExtraTemplateList = $.grep(
          productExtraTemplateList,
          function (item) {
            return item.id != productId;
          }
        );
        productExtraTemplateIdList = $.grep(
          productExtraTemplateIdList,
          function (item) {
            return item != productId;
          }
        );
        $("#product_extra_template_id option[value='" + productId + "']").prop(
          "disabled",
          false
        );
      } else {
        let productName = $(this).attr("data-product-name");
        extraProductTextList = $.grep(extraProductTextList, function (text) {
          return text !== productName;
        });
        extraProductTextListToShow = $.grep(
          extraProductTextListToShow,
          function (item) {
            return item.id != productId;
          }
        );
        $("#product_extra_template_id option[value='" + productId + "']").prop(
          "disabled",
          false
        );
      }
      showSelectedExtraProduct();
    });

    $(document).on("submit", "#choose-product-search-form", function (e) {
      e.preventDefault();
      let search_string = $("#choose_product_search_string").val();
      let location_id = $("body").attr("event_id");
      let product_type = 0;

      getProductList(location_id, search_string, product_type);
    });

    $(document).on("click", ".menu-product-item", function () {
      const productName = $(this).data("menu-product-name");
      const productId = $(this).attr("id");
      const productVolume = $(this).data("menu-product-volume");
      const tableBody = $("#menu-product-table-body");
      const menuProduct = {
        product_id: productId,
        name: productName,
        amount: 1,
      };

      const newRow = `
        <tr id="menu-product-${productId}">
            <td class="name-column">${productName}</td>
            <td>
                <div class="input-group">
                    <input type="number" data-product-id="${productId}" class="form-control amount-input" value="1" min="0" step="any"
                        onfocus="this.select()">
                    <div class="input-group-append">
                        <span class="input-group-text">${productVolume}</span>
                    </div>
                </div>
            </td>
            <td class="text-center">
                <button type="button" data-menu-product-id="${productId}" class="btn btn-danger btn-sm remove-menu-product">${
        language === "no" ? "Fjerne" : "Remove"
      }</button>
            </td>
        </tr>
    `;
      tableBody.append(newRow);

      menuProducts.push(menuProduct);

      $("#choose-product-modal").modal("hide");
    });

    $(document).on("change", ".amount-input", function () {
      const value = parseFloat(this.value) || 0;
      const productId = $(this).data("product-id");

      const index = menuProducts.findIndex(
        (product) => product.product_id == productId
      );

      if (index !== -1) {
        menuProducts[index].amount = value;
      }
    });

    $(document).on("click", ".remove-menu-product", function (e) {
      e.preventDefault();
      const productId = $(this).data("menu-product-id");
      $(this).closest("tr").remove();

      const index = menuProducts.findIndex(
        (product) => product.product_id == productId
      );

      if (index !== -1) {
        menuProducts.splice(index, 1);
      }
    });

    $(document).on("change", "#is_menu_product", function () {
      if ($(this).is(":checked")) {
        $("#choose-product-button").show();
      } else {
        $("#choose-product-button").hide();
      }
    });

    if (
      $("#event_ticket_category_id").val() === undefined ||
      $("#event_ticket_category_id").val() == null ||
      $("#event_ticket_category_id").val() === ""
    ) {
      let event_ticket_category_new_ajax_lock = false;

      $(document).on("submit", "#event_ticket_category_form", function () {
        if (!event_ticket_category_new_ajax_lock) {
          let event_id = $("#event_id").val();
          let product_type = parseInt($("#product_type").val());

          event_ticket_category_new_ajax_lock = true;

          $(":disabled").each(function () {
            $(this).removeAttr("disabled");
          });

          let formData = new FormData(
            document.getElementById("event_ticket_category_form")
          );
          if (extraProductIdList.length > 0) {
            formData.append("extraProductIdList", extraProductIdList);
          }
          if (extraProductTextList.length > 0) {
            formData.append("extraProductTextList", extraProductTextList);
          }
          if (productExtraTemplateIdList.length > 0) {
            formData.append(
              "productExtraTemplateIdList",
              productExtraTemplateIdList
            );
          }

          if (menuProducts.length > 0) {
            formData.append("menu_products", JSON.stringify(menuProducts));
          }

          $.ajax({
            type: "POST",
            url: "/ajax/organizer_event_ticket_category/new",
            data: formData,
            enctype: "multipart/form-data",
            async: true,
            cache: false,
            processData: false,
            contentType: false,
          })
            .done(function (data) {
              if (
                data.message !== undefined &&
                data.message !== null &&
                data.product_id !== undefined &&
                data.product_id !== null
              ) {
                prependAlert(
                  "#div_event_ticket_category_button",
                  data.message,
                  "success",
                  null,
                  "#event_ticket_category_form"
                );
                $(".card-box-to-hide").hide();
                $("#event_ticket_category_button").hide();

                const params = new URLSearchParams(window.location.search);
                const page = params.get("page");

                setTimeout(function () {
                  if (product_type === 0) {
                    document.location.href =
                      "/location/" +
                      event_id +
                      "/product" +
                      (page ? "?page=" + page : "");
                  } else {
                    document.location.href =
                      "/location/" +
                      event_id +
                      "/product/extra" +
                      (page ? "?page=" + page : "");
                  }
                }, 2000);
              }
            })
            .fail(function (data) {
              if (
                data.responseJSON.redirect_url !== undefined &&
                data.responseJSON.redirect_url !== null
              ) {
                document.location.href = data.responseJSON.redirect_url;
              }

              if (
                data.responseJSON.error_message !== undefined &&
                data.responseJSON.error_message !== null
              ) {
                prependAlert(
                  "#div_event_ticket_category_button",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#event_ticket_category_form"
                );
              }

              event_ticket_category_new_ajax_lock = false;
            })
            .always(function () {
              event_ticket_category_new_ajax_lock = false;
            });
        }

        return false;
      });
    }

    function showSelectedExtraProduct() {
      $("#product-extra-list-sortable").empty();
      let htmlToRender = "";
      $.map(extraProductList, function (item) {
        let productId = item.id;
        let productName = item.name;
        let htmlString = `<li class="list-group-item d-flex justify-content-between align-items-center product-extra">
        <div>${productName}</div>
        <span data-product-id=${productId} data-product-type="extra" class="product-extra-delete-button">
            <i class="material-icons">
                delete
            </i>
        </span>
    </li>`;

        htmlToRender += htmlString;
      });
      $.map(extraProductTextListToShow, function (item) {
        let productId = item.id;
        let productName = item.name;
        let htmlString = `<li class="list-group-item d-flex justify-content-between align-items-center product-extra">
        <div>${productName}</div>
        <span data-product-id=${productId} data-product-type="text" data-product-name=${productName} class="product-extra-delete-button">
            <i class="material-icons">
                delete
            </i>
        </span>
    </li>`;

        htmlToRender += htmlString;
      });
      $.map(productExtraTemplateList, function (item) {
        let productId = item.id;
        let productName = item.name;
        let htmlString = `<li class="list-group-item d-flex justify-content-between align-items-center product-extra">
        <div>${productName}</div>
        <span data-product-id=${productId} data-product-type="extra-template" class="product-extra-delete-button">
            <i class="material-icons">
                delete
            </i>
        </span>
    </li>`;

        htmlToRender += htmlString;
      });
      $("#product-extra-list-sortable").html(htmlToRender);
      popupAlert();
    }

    function getProductList(location_id, search_string, product_type) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/menu_product_search",
        data: {
          location_id,
          search_string,
          product_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#choose-product-list-container").empty();
            $("#choose-product-list-container").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function updateInputValues(data) {
      if (
        data.productPrice !== undefined &&
        data.productPrice !== null &&
        data.priceExMva !== undefined &&
        data.priceExMva !== null &&
        data.priceIncMva !== undefined &&
        data.priceIncMva !== null &&
        data.degreeOfCoverage !== undefined &&
        data.degreeOfCoverage !== null &&
        data.coverageOfContribution !== undefined &&
        data.coverageOfContribution !== null &&
        data.mva !== undefined &&
        data.mva !== null
      ) {
        priceExMva = data.priceExMva;
        priceIncMva = data.priceIncMva;
        degreeOfCoverage = data.degreeOfCoverage;
        coverageOfContribution = data.coverageOfContribution;
        mva = data.mva;
        productPrice = data.productPrice;
        $("#coverage_contribution").val(
          data.coverageOfContribution.replace(".", ",")
        );
        $("#price-ex-mva-value").val(data.priceExMva.replace(".", ","));
        $("#price-inc-mva-value").val(data.priceIncMva.replace(".", ","));
        $("#ticket_price_ex_vat").val(data.productPrice.replace(".", ","));
        $("#degree_of_coverage").val(data.degreeOfCoverage.replace(".", ","));
      }
    }
  }
});
