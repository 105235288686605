$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let location_id = $("body").attr("event_id");

  if (p === "organizer_event_ticket_category" && a === "list") {
    function formatNumber(number) {
      if (Number.isInteger(number)) {
        return number.toLocaleString("no-NO");
      } else {
        return number.toLocaleString("no-NO", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    $(document).on("change", "#product-live-edit", function (e) {
      e.preventDefault();

      let live_edit = 0;
      let search_string = $("#search_string").val();
      let product_type = $("#product_type").val();
      let supplier_id = $("#sort").data("supplier-id");
      let page = $("#event_ticket_category_table").attr("data-page");
      let isLiveEdit = $(this).prop("checked");

      if (isLiveEdit) {
        live_edit = 1;
      } else {
        live_edit = 0;
      }
      let formData = new FormData();
      formData.append("live_edit", live_edit);
      formData.append("search_string", search_string);
      formData.append("location_id", location_id);
      formData.append("product_type", product_type);
      formData.append("supplier_id", supplier_id);
      formData.append("page", page);
      formData.append("status", 1);

      getProductList(formData);
    });

    //update product

    $(document).on("change", ".product-live-edit", function (e) {
      let productId = $(this)
        .closest("tr")
        .attr("data-event-ticket-category-id");
      let columnToUpdate = $(this).attr("name");
      let inputedValue = $(this).val().replace(",", ".");

      let formData = new FormData();
      formData.append("product_id", productId);
      let url = "";
      let updateValue = null;
      let product_price = parseInt($(`#product-price-${productId}`).val());
      let selectedMva = $(`#product-mva-${productId}`)
        .find("option:selected")
        .text();
      let mva = parseInt(selectedMva);
      formData.append("product_id", productId);
      formData.append("target", columnToUpdate);
      if (
        columnToUpdate === "name" ||
        columnToUpdate === "supplier_id" ||
        columnToUpdate == "product_group_unique_id" ||
        columnToUpdate == "sku"
      ) {
        updateValue = $(this).val();
        url = "/ajax/organizer_event_ticket_category/live_update_product";

        formData.append("update_value", updateValue);
      } else {
        if (isNaN(inputedValue)) {
          return alert("Verdien må være et tall");
        }
        updateValue = formattedValueForNumberInput(inputedValue);
        url =
          "/ajax/organizer_event_ticket_category/live_update_product_price_group";

        formData.append("update_value", updateValue);
      }

      if (columnToUpdate === "degree_of_coverage") {
        if (!product_price && !mva) {
          return;
        }
        let degree_of_coverage = updateValue;
        let price_out_ex_mva =
          parseFloat(product_price) * parseFloat(degree_of_coverage);
        let coverage_contribution = price_out_ex_mva - product_price;
        let ticket_price_out_inc_mva = price_out_ex_mva * (1 + mva / 100);

        $(this).val(formattedPriceToShow(updateValue));
        $(`#coverage-contribution-${productId}`).val(
          formattedPriceToShow(coverage_contribution)
        );
        $(`#ticket-price-out-ex-mva-${productId}`).val(
          formattedPriceToShow(price_out_ex_mva)
        );
        $(`#ticket-price-out-inc-mva-${productId}`).val(
          formattedPriceToShow(ticket_price_out_inc_mva)
        );
      } else if (columnToUpdate === "coverage_contribution") {
        let coverage_contribution = updateValue;
        let price_out_ex_mva = coverage_contribution + product_price;
        let degree_of_coverage = price_out_ex_mva / product_price;
        let ticket_price_out_inc_mva = price_out_ex_mva * (1 + mva / 100);

        $(this).val(formattedPriceToShow(updateValue));
        $(`#degree-of-coverage-${productId}`).val(
          formattedPriceToShow(degree_of_coverage)
        );
        $(`#ticket-price-out-ex-mva-${productId}`).val(
          formattedPriceToShow(price_out_ex_mva)
        );
        $(`#ticket-price-out-inc-mva-${productId}`).val(
          formattedPriceToShow(ticket_price_out_inc_mva)
        );
      } else if (columnToUpdate === "price_out_ex_mva") {
        let price_out_ex_mva = updateValue;
        let coverage_contribution =
          parseFloat(price_out_ex_mva) - parseFloat(product_price);

        let degree_of_coverage = price_out_ex_mva / product_price;
        let ticket_price_out_inc_mva = formattedValueForNumberInput(
          price_out_ex_mva * (1 + mva / 100)
        );
        $(this).val(formattedPriceToShow(updateValue));
        $(`#coverage-contribution-${productId}`).val(
          formattedPriceToShow(coverage_contribution)
        );
        $(`#degree-of-coverage-${productId}`).val(
          formattedPriceToShow(degree_of_coverage)
        );
        $(`#ticket-price-out-inc-mva-${productId}`).val(
          formattedPriceToShow(ticket_price_out_inc_mva)
        );
      } else if (columnToUpdate === "mva") {
        let vatCodeNoOutputVat = $("#vat-code-group").attr(
          "data-vat-code-no-output-vat"
        );
        let vatCodeOutputVatFood = $("#vat-code-group").attr(
          "data-vat-code-output-vat-food"
        );
        let vatCodeOutputVatReducedRate = $("#vat-code-group").attr(
          "data-vat-code-output-vat-reduced-rate"
        );
        let vatCodeNoOutputVatPercent = $("#vat-percent-group").attr(
          "data-vat-code-no-output-vat-percent"
        );
        let vatCodeOutputVatFoodPercent = $("#vat-percent-group").attr(
          "data-vat-code-output-vat-food-percent"
        );
        let vatCodeOutputVatReducedRatePercent = $("#vat-percent-group").attr(
          "data-vat-code-output-vat-reduced-rate-percent"
        );
        let vatCodeOutputVatRegularRatePercent = $("#vat-percent-group").attr(
          "data-vat-code-output-vat-regular-rate-percent"
        );

        if (updateValue == vatCodeNoOutputVat) {
          mva = vatCodeNoOutputVatPercent;
        } else if (updateValue == vatCodeOutputVatFood) {
          mva = vatCodeOutputVatFoodPercent;
        } else if (updateValue == vatCodeOutputVatReducedRate) {
          mva = vatCodeOutputVatReducedRatePercent;
        } else {
          mva = vatCodeOutputVatRegularRatePercent;
        }
        let coverage_contribution = $(
          `#coverage-contribution-${productId}`
        ).val();
        let degree_of_coverage = $(`#degree-of-coverage-${productId}`).val();
        let price_out_ex_mva = $(`#ticket-price-out-ex-mva-${productId}`).val();

        let ticket_price_out_inc_mva =
          parseFloat(price_out_ex_mva) * (1 + parseInt(mva) / 100);

        $(`#ticket-price-out-inc-mva-${productId}`).val(
          formattedPriceToShow(ticket_price_out_inc_mva)
        );
      } else if (columnToUpdate === "ticket_price_ex_vat") {
        let coverage_contribution = $(
          `#coverage-contribution-${productId}`
        ).val();
        let degree_of_coverage = $(`#degree-of-coverage-${productId}`).val();
        let price_out_ex_mva = $(`#ticket-price-out-ex-mva-${productId}`).val();

        product_price = parseFloat(updateValue);
        price_out_ex_mva = formattedValueForNumberInput(
          parseFloat(product_price) * parseFloat(degree_of_coverage)
        );
        coverage_contribution = price_out_ex_mva - product_price;
        let ticket_price_out_inc_mva = price_out_ex_mva * (1 + mva / 100);

        $(`#coverage-contribution-${productId}`).val(
          formattedPriceToShow(coverage_contribution)
        );
        $(`#ticket-price-out-ex-mva-${productId}`).val(
          formattedPriceToShow(price_out_ex_mva)
        );
        $(`#ticket-price-out-inc-mva-${productId}`).val(
          formattedPriceToShow(ticket_price_out_inc_mva)
        );
      } else if (columnToUpdate === "ticket_price_out_inc_mva") {
        let ticket_price_out_inc_mva = updateValue;
        let price_out_ex_mva = ticket_price_out_inc_mva / (1 + mva / 100);

        let coverage_contribution = price_out_ex_mva - product_price;

        let degree_of_coverage = price_out_ex_mva / product_price;
        $(this).val(formattedPriceToShow(updateValue));
        $(`#ticket-price-out-ex-mva-${productId}`).val(
          formattedPriceToShow(price_out_ex_mva)
        );
        $(`#coverage-contribution-${productId}`).val(
          formattedPriceToShow(coverage_contribution)
        );
        $(`#degree-of-coverage-${productId}`).val(
          formattedPriceToShow(degree_of_coverage)
        );
      }

      productLiveUpdate(formData, url);
    });

    $(document).on("change", ".edit-product-image", function (event) {
      const input = event.target;
      const container = input.parentElement;
      const productId = input.getAttribute("data-product-id");

      if (!productId) {
        return;
      }

      $(container).find(".spinner").css("display", "flex");

      const reader = new FileReader();

      reader.onload = function () {
        const img = container.querySelector("img");
        const imageData = reader.result;

        if (img) {
          img.src = imageData;
        } else {
          const imageTag = document.createElement("img");
          const thumbnail = document.getElementById("thumbnail");
          imageTag.style = "max-height: 50px; width:auto";

          imageTag.src = imageData;
          container.removeChild(thumbnail);
          container.append(imageTag);
        }
      };

      reader.readAsDataURL(input.files[0]);

      const formData = new FormData();
      formData.append("picture", input.files[0]);
      formData.append("event_id", location_id);
      formData.append("event_ticket_category_id", productId);

      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/update",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          $(".spinner").hide();
        })
        .fail(function (data) {
          $(".spinner").hide();
        });
    });

    function productLiveUpdate(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          popupAlert();
          return true;
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getProductList(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
