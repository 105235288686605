$(document).ready(function () {
  $(document).on("change", ".user-role", function () {
    var new_role = $(this).children("option:selected").val();
    var current_role = $(this).attr("data-current-role");
    var user_id = $(this).attr("data-user-id");

    $.ajax({
      type: "POST",
      url: "/ajax/organizer_settings_users/update_user",
      data: {
        user_id: user_id,
        role: new_role,
      },
    })
      .done(function (data) {
        if (data === true) {
          $("#new-role-" + user_id).attr("data-current-role", new_role);
          popupAlert();
        }
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }

        if (
          data.responseJSON.error_message !== undefined &&
          data.responseJSON.error_message !== null
        ) {
          $("#new-role-" + user_id).val(current_role);
          inserAfterAlertTr(
            "#user-" + user_id,
            data.responseJSON.error_message,
            "danger"
          );
        }
      });

    return false;
  });

  $(document).on("keydown", ".pin-input", function (event) {
    const length = event.target.value.length;

    var charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode === 46 || charCode === 43 || charCode === 45 || length >= 8) &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    }
  });

  $(document).on("blur", ".pin-input", function () {
    var pin = $(this).val();
    if (pin.length === 0 || (pin.length >= 4 && pin.length <= 8)) {
      return true;
    } else {
      alert("Vennligst skriv inn en PIN-kode på mellom 4 og 8 tegn");
      return false;
    }
  });

  $(document).on("click", ".edit-pin", function () {
    const userId = $(this).data("user-id");
    const pinInput = $(`#pin-input-${userId}`);

    $(this).hide();
    $(`#confirm-pin-${userId}`).show();
    pinInput.attr("type", "number");
    pinInput.removeAttr("disabled");
  });

  $(document).on("click", ".confirm-pin", function (e) {
    const userId = $(this).data("user-id");
    const pinInput = $(`#pin-input-${userId}`);
    const userPin = pinInput.val();

    if (userPin.length > 0 && (userPin.length < 4 || userPin.length > 8)) {
      alert("Vennligst skriv inn en PIN-kode på mellom 4 og 8 tegn");
      return false;
    }

    $(".pin-error").empty();
    $(`#user-${userId}-error`).addClass("d-none");

    $.ajax({
      type: "POST",
      url: "/ajax/organizer_settings_users/update_user",
      data: {
        user_id: userId,
        pin: userPin,
      },
    })
      .done(function (data) {
        if (data === true) {
          popupAlert();

          $(e.target).hide();
          $(`#edit-pin-${userId}`).show();
          pinInput.attr("disabled", true);
          pinInput.attr("type", "password");
        }
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }

        if (
          data.responseJSON.error_message !== undefined &&
          data.responseJSON.error_message !== null
        ) {
          const $errorRow = $("#user-" + userId + "-error td");

          if ($errorRow.length) {
            $errorRow.html(
              `<div class="alert alert-danger">${data.responseJSON.error_message}</div>`
            );
            $errorRow.parent().removeClass("d-none");
          } else {
            console.error(`Error row not found for user ID: ${userId}`);
          }
        }
      });
  });
});
