$(document).ready(function () {
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery = $("body").attr("start_date") || null;
  let endDateFromQuery = $("body").attr("end_date") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;
  if (p === "statistic_payment") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );
    handleCustomerIdChange(getPaymentMethod);
    handleCustomPeriodChange(getPaymentMethod);
    handleTopProductGroupChange(getPaymentMethod);
    handleSupplierIdChange(getPaymentMethod);
    handleProductGroupChange(getPaymentMethod);
    handleUserChange(getPaymentMethod);
    handlePlatformChange(getPaymentMethod);
    handlePeriodChange(getPaymentMethod);
    handleEndDateChange(getPaymentMethod);
    handleStartDateChange(getPaymentMethod);
    handlePreviousButton(getPaymentMethod);
    handleNextButton(getPaymentMethod);
    handleCompareByChange(getPaymentMethod);

    function eventLineChart(seriesData, interval = null) {
      let categories = seriesData.map((item) => item.name); // Extract dates for x-axis categories

      // Prepare the series array
      let series = [
        {
          name: "Vipps",
          data: seriesData.map((item) => item.data[0][0]), // First line of Vipps
          stack: "Vipps",
        },
        {
          name: "Kort",
          data: seriesData.map((item) => item.data[0][1]), // First line of Kort
          stack: "Kort",
        },
        {
          name: "Terminal",
          data: seriesData.map((item) => item.data[0][2]), // First line of Terminal
          stack: "Terminal",
          color: "grey",
        },
        {
          name: "Kontanter",
          data: seriesData.map((item) => item.data[0][3]), // First line of Kontanter
          stack: "Kontanter",
        },
      ];

      // Conditionally add last week and last year data if compareBy is not null or "0"
      if (compareBy && compareBy !== "0") {
        series = [
          {
            name: "Vipps",
            data: seriesData.map((item) => item.data[0][0]), // First line of Vipps
            stack: "Vipps",
          },
          {
            name: "Vipps forrige uke",
            data: seriesData.map((item) => item.data[1][0]), // Second line of Vipps
            stack: "Vipps", // Same stack as "Vipps" to group the columns
          },
          {
            name: "Vipps forrige år",
            data: seriesData.map((item) => item.data[2][0]), // Third line of Vipps
            stack: "Vipps", // Same stack as "Vipps" to group the columns
          },
          {
            name: "Kort",
            data: seriesData.map((item) => item.data[0][1]), // First line of Kort
            stack: "Kort",
          },
          {
            name: "Kort forrige uke",
            data: seriesData.map((item) => item.data[1][1]), // Second line of Kort
            stack: "Kort", // Same stack as "Kort" to group the columns
          },
          {
            name: "Kort forrige år",
            data: seriesData.map((item) => item.data[2][1]), // Third line of Kort
            stack: "Kort", // Same stack as "Kort" to group the columns
          },
          {
            name: "Terminal",
            data: seriesData.map((item) => item.data[0][2]), // First line of Terminal
            stack: "Terminal",
          },
          {
            name: "Terminal forrige uke",
            data: seriesData.map((item) => item.data[1][2]), // Second line of Terminal
            stack: "Terminal", // Same stack as "Terminal" to group the columns
          },
          {
            name: "Terminal forrige år",
            data: seriesData.map((item) => item.data[2][2]), // Third line of Terminal
            stack: "Terminal", // Same stack as "Terminal" to group the columns
          },
          {
            name: "Kontanter",
            data: seriesData.map((item) => item.data[0][3]), // First line of Kontanter
            stack: "Kontanter",
          },
          {
            name: "Kontanter forrige uke",
            data: seriesData.map((item) => item.data[1][3]), // Second line of Kontanter
            stack: "Kontanter", // Same stack as "Kontanter" to group the columns
          },
          {
            name: "Kontanter forrige år",
            data: seriesData.map((item) => item.data[2][3]), // Third line of Kontanter
            stack: "Kontanter", // Same stack as "Kontanter" to group the columns
          },
        ];
      }

      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: { type: "column" },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 2, // Adjust line width as needed
            stacking: "normal", // Enable stacking of columns in the same group
          },
        },
        xAxis: {
          categories: categories,
          labels: {
            formatter: function () {
              if (startDate.split("T")[0] == endDate.split("T")[0]) {
                return Highcharts.dateFormat("%H:%M", this.value);
              } else if (customPeriod === "week") {
                return "Uke" + " " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat("%B", this.value);
              } else {
                return Highcharts.dateFormat("%e / %b", this.value);
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          min: 0,
          title: {
            text: " ",
          },
        },
        series: series,
        tooltip: {
          formatter: function () {
            let tooltipText;
            let tooltipDate;
            let tooltipWeek;

            if (this.series.name.includes("uke")) {
              let date = moment(this.x);
              date.subtract(1, "week");
              tooltipDate = date.valueOf();
            } else if (this.series.name.includes("forrige")) {
              let date = moment(this.x);
              date.subtract(1, "year");
              tooltipDate = date.valueOf();
            } else {
              tooltipDate = this.x;
            }

            if (this.series.name.includes("uke")) {
              tooltipWeek = parseInt(this.x) - 1;
            } else {
              tooltipWeek = this.x;
            }

            if (startDate.split("T")[0] == endDate.split("T")[0]) {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                tooltipWeek +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }

    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }

    function getPaymentMethod(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0,
      customerId,
      compareBy = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_payment/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
          customerId,
          compareBy,
        },
      })
        .done(function (data) {
          if (data.paymentCounts) {
            $("#key-figure-vipps").text(
              formattedPriceToShow(data.paymentCounts.vippsCount)
            );
            $("#key-figure-card").text(
              formattedPriceToShow(data.paymentCounts.cardCount)
            );
            $("#key-figure-terminal").text(
              formattedPriceToShow(data.paymentCounts.terminalCount)
            );
            $("#key-figure-cash").text(
              formattedPriceToShow(data.paymentCounts.cashCount)
            );
          } else {
            $("#key-figure-vipps").text("0");
            $("#key-figure-card").text("0");
            $("#key-figure-terminal").text("0");
            $("#key-figure-cash").text("0");
          }
          if (data.data) {
            eventLineChart(data.data);
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function displayGraphDynamically(durationOfstartAndEndDate) {
      if (isMobile) {
        if (durationOfstartAndEndDate <= 7) {
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 8 &&
          durationOfstartAndEndDate < 49
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else if (isTablet) {
        if (durationOfstartAndEndDate <= 14) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 15 &&
          durationOfstartAndEndDate < 98
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else {
        if (durationOfstartAndEndDate <= 31) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 32 &&
          durationOfstartAndEndDate < 217
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      }
    }

    getPaymentMethod(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId
    );
    toggleCustomDatePicker();
  }
});
