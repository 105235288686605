$(document).ready(function () {
  let p = $("body").attr("p");
  const body = $("body");
  let orderStorageIds = null;
  let orderStorageIdentifierSelectAjaxLock = false;
  let isLongPress = false;
  let longPressTimer;
  let shop_type = $("body").attr("shop_type");
  let tableId = $("body").attr("table_id") || null;
  let selectedTableNumber = "";
  if (p === "events" || p === "events_self_service") {
    if (tableId) {
      locationCart.table_number = tableId;
      selectedTableNumber = tableId;
      $(".save_order_self_service").attr(
        "data-identifier",
        selectedTableNumber
      );
    }

    const organizerId = $(body).attr("organizer_id");
    const error_message = $(body).attr("error_message") ?? null;
    const locationId = $("#location").data("location-id");
    let posType = $("#location-products").data("pos-type");
    let orderStorageIdentifierFormSubmitAjaxLock = false;

    if (error_message && localStorage.getItem("saved_table_id")) {
      selectedTableNumber = localStorage.getItem("saved_table_id");
    }

    $(document).on("click", ".self-service-table", function (e) {
      e.preventDefault();
      selectedTableNumber = $(this).attr("data-table-name");
      locationCart.table_number = selectedTableNumber;
      $("#self-service-table-select-btn").text(selectedTableNumber);
      $(".save_order_self_service").attr(
        "data-identifier",
        selectedTableNumber
      );
      $(".save_order_self_service").attr("data-extra-table", "");
      $("#selfServiceTableModel").modal("hide");

      $(".order-button").each(function () {
        if ($(this).is("[data-toggle]") && 
            ($(this).data("payment-method") === "nets_card" || 
             $(this).data("payment-method") === "nets_vipps")) {
            $(this).prop("disabled", true);
        } else {
            $(this).prop("disabled", false);
        }
    });
      $(".save_order_self_service").prop("disabled", false);
    });

    $(document).on(
      "click",
      "#view-saved-table, #view-saved-table-contanier",
      function (e) {
        e.preventDefault();
        emptyAllCart();
        if (!orderStorageIdentifierSelectAjaxLock && !isLongPress) {
          orderStorageIdentifierSelectAjaxLock = true;

          if (locationCart.number_of_products == 0) {
            const identifier = $(this).attr("data-table-id");
            const extraTable = "";
            const cart_products = locationCart.products;

            $.ajax({
              type: "POST",
              url: "/ajax/order/get_saved_order_table",
              data: {
                organizer_id: organizerId,
                location_id: locationId,
                table_id: identifier,
                extra_table_id: extraTable,
                cart_products,
              },
            })
              .done(function (data) {
                if (
                  data.location_id !== undefined &&
                  data.location_id !== null &&
                  data.location_id == locationId
                ) {
                  $("#order-storage-list-modal").modal("hide");
                  $(".modal-backdrop").remove();
                  if (posType === "classic") {
                    setClassicLocationCart(data);
                  } else {
                    setLocationCart(data);
                  }
                }
              })
              .fail(function (data) {
                if (
                  data.responseJSON.redirect_url !== undefined &&
                  data.responseJSON.redirect_url !== null
                ) {
                  document.location.href = data.responseJSON.redirect_url;
                }
              })
              .always(function () {
                setTimeout(function () {
                  orderStorageIdentifierSelectAjaxLock = false;
                }, 3000);
              });
          }
        }
      }
    );

    $(document).on("hide.bs.modal", "#cart-modal", function (event) {
      $(".modal-backdrop").remove();
      orderStorageIdentifierSelectAjaxLock = false;
    });

    $(document).on("click", ".save_order_self_service", function (e) {
      e.preventDefault();
      if (
        !$(this).attr("data-identifier") ||
        $(this).attr("data-identifier") == ""
      ) {
        $(".self-service-table").focus();
        return alert("Velg bord først");
      }
      selectedTableNumber = $(this).attr("data-identifier");

      const identifier = $(this).data("identifier");
      const cart_products = locationCart.products;
      const extraTable = $(this).attr("data-extra-table");

      if (cart_products.length <= 0) {
        return alert("No item in cart");
      }

      $.ajax({
        type: "POST",
        url: "/ajax/order/save_order_product",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          table_id: identifier,
          extra_table_id: extraTable,
          cart_products,
        },
      })
        .done(function (data) {
          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationId
          ) {
            $("#view-saved-table").attr("hidden", false);
            emptyAllCart();
            popupAlert();
            $(".table-responsive").empty();
            $("#view-saved-table").attr("data-table-id", selectedTableNumber);
            localStorage.setItem("saved_table_id", selectedTableNumber);
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
    $(document).on("click", "#view-saved-table", function (e) {
      e.preventDefault();
    });
    // $(document).on("click", "", function (e) {
    //   e.preventDefault();
    // });
    // $(document).on("click", "", function (e) {
    //   e.preventDefault();
    // });

    $(document).on("click", "#order-storage-modal", function () {
      let products_in_cart = 0;
      if (
        locationCart.number_of_products !== undefined &&
        locationCart.number_of_products >= 0
      ) {
        products_in_cart = locationCart.number_of_products;
      }

      $.ajax({
        type: "POST",
        url: "/ajax/order/get_order_storage_modal",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          products_in_cart: products_in_cart,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            if ($("#order-storage-list-modal").length > 0) {
              $("#order-storage-list-modal").remove();
            }
            $("#content-body").append(data.html);
            $("#order-storage-list-modal").modal("show");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        })
        .always(function () {
          setTimeout(function () {
            orderStorageIdentifierSelectAjaxLock = false;
          }, 3000);
        });
    });

    $(document).on("click", "#extra-table", function () {
      $("#context-menu").hide();
      $("#extraTableModal").modal("show");
      $("#extraTableName").focus();
    });
    $(document).on("click", "#delete-table", function () {
      $("#context-menu").hide();
      $("#deleteTableModal").modal("show");
    });

    $(document).on("click", "#extra-table-name-cancle", function () {
      $("#extraTableModal").modal("hide");
    });
    $(document).on("click", "#delete-table-cancle", function () {
      $("#deleteTableModal").modal("hide");
    });

    $(document).on("click", "#extra-table-name-confirm", function () {
      let extraTableName = $("#extraTableName").val();
      let oldTableName = $("#extra-table").attr("data-identifier");
      $.ajax({
        type: "POST",
        url: "/ajax/order/extra_table",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          original_table_name: oldTableName,
          extra_table_name: extraTableName,
        },
      })
        .done(function (data) {
          if (data) {
            $("#order-table-list-modal").modal("hide");
            $("#order-storage-list-modal").modal("hide");
            $(".modal-backdrop").remove();
            popupAlert();
            $("#order-storage-modal").click();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
    $(document).on("click", "#delete-table-confirm", function () {
      let tableToDelete = $("#delete-table").attr("data-identifier");
      $.ajax({
        type: "POST",
        url: "/ajax/order/delete_product_table",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          table_id: tableToDelete,
        },
      })
        .done(function (data) {
          if (data.success) {
            $("#order-table-list-modal").modal("hide");
            $("#order-storage-list-modal").modal("hide");
            $(".modal-backdrop").remove();
            popupAlert();
            $("#order-storage-modal").click();
          } else {
            alert("Cannot delete this table");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", "#move-table", function () {
      let identifier = $(this).data("identifier");
      let products_in_cart = 0;
      if (
        locationCart.number_of_products !== undefined &&
        locationCart.number_of_products >= 0
      ) {
        products_in_cart = locationCart.number_of_products;
      }
      $.ajax({
        type: "POST",
        url: "/ajax/order/get_order_table_modal",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          products_in_cart: products_in_cart,
          identifier,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            if ($("#order-table-list-modal").length > 0) {
              $("#order-table-list-modal").remove();
            }
            $("#order-storage-list-modal").after(data.html);
            $("#order-table-list-modal").modal("show");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        })
        .always(function () {
          setTimeout(function () {
            orderStorageIdentifierSelectAjaxLock = false;
          }, 3000);
        });
    });

    $(document).on("click", ".order-table-move", function () {
      let fromIdentifier = $(this).data("from-identifier");
      let toIdentifier = $(this).data("identifier");

      $.ajax({
        type: "POST",
        url: "/ajax/order/move_table",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          fromIdentifier,
          toIdentifier,
        },
      })
        .done(function (data) {
          if (data) {
            $("#order-table-list-modal").modal("hide");
            $("#order-storage-list-modal").modal("hide");
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on(
      "click",
      ".order-storage-list .order-storage-list-identifier",
      function () {
        if (!orderStorageIdentifierSelectAjaxLock && !isLongPress) {
          orderStorageIdentifierSelectAjaxLock = true;

          if (locationCart.number_of_products == 0) {
            const identifier = $(this).data("identifier");
            const extraTable = $(this).attr("data-extra-table");
            const cart_products = locationCart.products;

            $.ajax({
              type: "POST",
              url: "/ajax/order/get_saved_order_table",
              data: {
                organizer_id: organizerId,
                location_id: locationId,
                table_id: identifier,
                extra_table_id: extraTable,
                cart_products,
              },
            })
              .done(function (data) {
                if (
                  data.location_id !== undefined &&
                  data.location_id !== null &&
                  data.location_id == locationId
                ) {
                  $("#order-storage-list-modal").modal("hide");
                  if (posType === "classic") {
                    setClassicLocationCart(data);
                  } else {
                    setLocationCart(data);
                  }
                }
              })
              .fail(function (data) {
                if (
                  data.responseJSON.redirect_url !== undefined &&
                  data.responseJSON.redirect_url !== null
                ) {
                  document.location.href = data.responseJSON.redirect_url;
                }
              })
              .always(function () {
                setTimeout(function () {
                  orderStorageIdentifierSelectAjaxLock = false;
                }, 3000);
              });
          } else {
            const identifier = $(this).data("identifier");
            const cart_products = locationCart.products;
            const extraTable = $(this).attr("data-extra-table");

            $.ajax({
              type: "POST",
              url: "/ajax/order/save_order_product",
              data: {
                organizer_id: organizerId,
                location_id: locationId,
                table_id: identifier,
                extra_table_id: extraTable,
                cart_products,
              },
            })
              .done(function (data) {
                if (
                  data.location_id !== undefined &&
                  data.location_id !== null &&
                  data.location_id == locationId
                ) {
                  $("#order-storage-list-modal").modal("hide");
                  emptyAllCart();
                }
              })
              .fail(function (data) {
                if (
                  data.responseJSON.redirect_url !== undefined &&
                  data.responseJSON.redirect_url !== null
                ) {
                  document.location.href = data.responseJSON.redirect_url;
                }
              });
          }
        }
      }
    );

    $(document).on(
      "mousedown touchstart",
      ".order-storage-list .order-storage-list-identifier",
      function (e) {
        const identifier = $(this).data("identifier");
        const parentModal = $(this).closest(".modal");
        const elementOffset = $(this).offset();
        const modalOffset = parentModal.offset();
        const elementHeight = $(this).outerHeight();
        const elementWidth = $(this).outerWidth();
        const numberOfProductsInCart = $("#order-storage-list-modal").attr(
          "data-cart-count"
        );
        if (Number(numberOfProductsInCart) > 0) {
          isLongPress = false;
          return;
        }
        longPressTimer = setTimeout(function () {
          isLongPress = true;
          showContextMenu(
            identifier,
            elementOffset,
            modalOffset,
            elementHeight,
            elementWidth,
            parentModal
          );
        }, 1000);
      }
    );

    function showContextMenu(
      identifier,
      elementOffset,
      modalOffset,
      elementHeight,
      elementWidth,
      parentModal
    ) {
      const contextMenu = $("#context-menu");
      const moveTableButton = $("#move-table");
      const extraTableButton = $("#extra-table");
      const deleteTableButton = $("#delete-table");
      contextMenu.attr("data-identifier", identifier);
      extraTableButton.attr("data-identifier", identifier);
      deleteTableButton.attr("data-identifier", identifier);
      moveTableButton.attr("data-identifier", identifier);
      contextMenu.css({
        top: elementOffset.top - modalOffset.top + elementHeight / 1.2 + "px",
        left:
          elementOffset.left -
          modalOffset.left +
          elementWidth -
          contextMenu.outerWidth() +
          "px",
      });
      contextMenu.appendTo(parentModal); // Ensure the context menu is within the modal
      contextMenu.show();
    }

    $(document).on(
      "mouseup touchend",
      ".order-storage-list .order-storage-list-identifier",
      function () {
        clearTimeout(longPressTimer);
      }
    );
    $(document).on(
      "mouseleave touchend",
      ".order-storage-list .order-storage-list-identifier",
      function () {
        clearTimeout(longPressTimer);
        isLongPress = false;
      }
    );
    // Hide context menu when clicking outside
    $(document).on("click touchstart", function (e) {
      if (
        !$(e.target).closest(
          ".order-storage-list .order-storage-list-identifier, #context-menu"
        ).length
      ) {
        $("#context-menu").hide();
        clearTimeout(longPressTimer);
        isLongPress = false;
      }
    });

    $(document).on("click touchstart", "#context-menu", function (e) {
      e.stopPropagation();
    });

    $(document).on("submit", "#order-storage-identifier-form", function (e) {
      e.preventDefault();
      const identifier = $(
        "#order-storage-identifier-form #order-storage-identifier-new"
      ).val();
      const extraTable = $(this).attr("data-extra-table") ?? "";
      if (!orderStorageIdentifierFormSubmitAjaxLock && identifier != "") {
        orderStorageIdentifierFormSubmitAjaxLock = true;
        $("#order-storage-list-modal").modal("hide");
        locationCart.order_storage_identifier = identifier;
        const cart_products = locationCart.products;

        $.ajax({
          type: "POST",
          url: "/ajax/order/save_order_product",
          data: {
            organizer_id: organizerId,
            location_id: locationId,
            table_id: identifier,
            extra_table_id: extraTable,
            cart_products,
          },
        })
          .done(function (data) {
            if (
              data.location_id !== undefined &&
              data.location_id !== null &&
              data.location_id == locationId
            ) {
              $("#order-storage-list-modal").modal("hide");
              emptyAllCart();
              orderStorageIdentifierSelectAjaxLock = false;
              orderStorageIdentifierFormSubmitAjaxLock = false;
            }
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }
          });
      }
      return false;
    });
  }
});
